/* You can add global styles to this file, and also import other style files */

body {
    background-image: url(https://wiki.itcsoluciones.com/images/BackgroundNuvalid.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    // font-family: sans-serif;
}

@media (max-width: 1440px) {
    body {
        background-size: auto;
    }
}

.strong-texts{
    color: #0f509b
}

.image-logo{
    margin-top: 25px; 
    width: 100px; 
    height: 100px;
}

.container-text-verify {
    text-align: center; 
    margin-top: 40px; 
    font-size: x-large;
}

.container-text-itc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 25px; 
    font-size: large;
    padding: 0px 10px;
}

.container-logo {
    text-align: center;
}

.mat-form-contraseña {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
}
  
.full-width {
    width: 100%;
}

.space-final{
    margin-top: 20px;
}

.space-init{
    margin-bottom: 20px;
}

.success-snackbar {
    background-color: #4caf50; /* Verde para éxito */
    color: white;
}

.mat-typography{
    line-height: normal;
}

html, body { 
    height: 100%; 
}

body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
}